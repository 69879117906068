export const supportLinks = {
  cimode: '',
  de: 'https://902292-yesty-redemption-shop.trengohelp.com/de',
  en: 'https://902292-yesty-redemption-shop.trengohelp.com/en_GB',
  'en-GB': 'https://902292-yesty-redemption-shop.trengohelp.com/en_GB',
  'en-US': 'https://902292-yesty-redemption-shop.trengohelp.com/en_GB', // currently no en-US link
  es: 'https://902292-yesty-redemption-shop.trengohelp.com/es',
  fr: 'https://902292-yesty-redemption-shop.trengohelp.com/fr',
  it: 'https://902292-yesty-redemption-shop.trengohelp.com/it',
  nl: 'https://902292-yesty-redemption-shop.trengohelp.com/nl',
  pl: 'https://902292-yesty-redemption-shop.trengohelp.com/pl',
}

export const eonSupportLinks = {
  de: 'https://301313-yesty-eon.trengohelp.com/de',
  en: 'https://301313-yesty-eon.trengohelp.com/de',
}
