import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Markdown from 'react-markdown'

import { AboutFooter } from '@components/atoms/About/About'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { TitleHeaderH1LCSS, TitleHeaderH1SCSS } from '@components/atoms/typography/css'
import { Fonts } from '@components/atoms/typography/Fonts'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { colours, gradients } from '@configs/colours'
import { mediaQueries } from '@utils/mediaQueries'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { BodyLargeCSS } from '@components/atoms/typography/css/BodyLargeCSS'
import { MiscelaneousSpecialTitleBoldCSS } from '@components/atoms/typography/css/MiscelaneouSpecialTitleBoldCSS'
import { InfoWithBadge } from '@components/atoms/InfoWithBadge/InfoWithBadge'
import { Fragment, useEffect, useState } from 'react'
import { GradientCard } from '@components/atoms/GradientCard/GradientCard'
import { Modal } from '@components/atoms/modals/Modal'
import { ShareModalContent } from '@components/molecules/refer/ShareModalContent'
import { ReferFriendFAQ } from '@components/molecules/refer/FAQ'
import { useSettingsContext } from '@hooks/useSettings'
import { getReferralLink } from '@utils/getReferrals'
import { useMainKeyContext } from '@hooks/useMainKey'
import { Banner } from '@components/atoms/tooltips/Banners/Banner'
import { ReferFriendPanelStatus } from '@typeDeclarations/referFriend'
import { useFeatureFlag } from 'configcat-react'
import { BodyRegularCSS } from '@components/atoms/typography/css/BodyRegularCSS'
import { MorePanels } from '@components/molecules/refer/MorePanels'
import { api } from '@services/api'
import { JoinablePanel } from '@services/api.types'
import { useUserContext } from '@hooks/useUserContext'
import { useHashFlowContext } from '@hooks/useHashFlowContext'

const Page: React.FC = () => {
  const { i18n, t } = useTranslation()
  const { value: referFriendFAQEnabled } = useFeatureFlag('shop_refer_friend_faq_enabled', false)
  const { value: panelsProgramFeatureFlag } = useFeatureFlag('panelsProgram', false)

  const { panel, referralCode, reloadShopConfig } = useSettingsContext()
  const { mainKey } = useMainKeyContext()
  const { email: userEmail, isLoggedIn } = useUserContext()
  const { hashFlows } = useHashFlowContext()

  const [shareModal, setShareModal] = useState(false)
  const [generatedReferralCode, setGeneratedReferralCode] = useState<string>()
  const [joinablePanels, setJoinablePanels] = useState<JoinablePanel[]>()

  const [customisedMessage, setCustomisedMessage] = useState('')
  const [editing, setEditing] = useState(false)

  const language = i18n.language

  const prefillEmail = mainKey ? hashFlows[mainKey]?.prefillEmail : undefined
  const panelEmail = userEmail || prefillEmail

  const termsAndConditions = panel?.content?.terms_and_conditions
  const textDescription = panel?.content?.text_description
  const referralLink = getReferralLink(referralCode)
  const statusActive = panel?.status === ReferFriendPanelStatus.Active

  const hasReferFriend = panel?.modules?.includes('refer_a_friend')
  const hasPanelBuilding = panel?.modules?.includes('panelbuilding')
  const showReferFriend = !!statusActive && !!hasReferFriend
  const showPanelBuilding = !!statusActive && !!hasPanelBuilding && !hasReferFriend

  useEffect(() => {
    if (mainKey) reloadShopConfig(mainKey)
    api.listPanels({ participantEmail: panelEmail }).then((r) => setJoinablePanels(r))
  }, [mainKey, reloadShopConfig, language])

  useEffect(() => {
    reloadShopConfig(mainKey)
  }, [isLoggedIn])

  useEffect(() => {
    if (textDescription) setCustomisedMessage(textDescription)
  }, [textDescription])

  return (
    <Root>
      {!statusActive && (
        <BannerWrapper>
          <StyledBanner type="tipIdea" title={t('referral.noActivePanel')}>
            {t('referral.noActivePanelDescription')}
          </StyledBanner>
        </BannerWrapper>
      )}
      {!!showReferFriend && (
        <Fragment>
          <Modal open={shareModal} onClose={() => setShareModal(false)}>
            <ShareModalContent
              referralCode={referralCode}
              textDescription={customisedMessage}
              generatedReferralCode={generatedReferralCode}
              setGeneratedReferralCode={setGeneratedReferralCode}
              onClose={() => setShareModal(false)}
            />
          </Modal>
          <IntroContent>
            <WidthBoundary>
              <Grid>
                <Info>
                  <Title>{t('referFriendsAndEarnMore')}</Title>
                  <Subtitle>{t('getExtraEachFriendYouRefer')}</Subtitle>
                  <div>
                    <RegularGray>
                      {t('helpUsBuildPanel')}
                      <br />
                      {termsAndConditions && (
                        <Trans
                          i18nKey="termsAndConditionsApply"
                          components={[
                            <LinkStyle href={termsAndConditions} key="idk" target="_blank" />,
                          ]}
                        />
                      )}
                    </RegularGray>
                  </div>
                </Info>
                <Frame>
                  <MainDescription>{t('shareWithFriend')}</MainDescription>
                  <RegularGray>{t('sharingIsCaring')}</RegularGray>
                  {statusActive && (
                    <BlueFrame
                      onClick={() => {
                        setEditing((v) => !v)
                      }}
                    >
                      {!editing && <Markdown className="__markdown">{customisedMessage}</Markdown>}
                      {editing && (
                        <Input
                          value={customisedMessage}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                          onChange={(e) => setCustomisedMessage(e.target.value)}
                        />
                      )}
                    </BlueFrame>
                  )}
                  <Buttons>
                    <PrimaryButton
                      iconRight={Icons.Share}
                      type="button"
                      onClick={() => {
                        setEditing(false)
                        setShareModal(true)
                      }}
                      disabled={!statusActive}
                    >
                      {t('share')}
                    </PrimaryButton>
                    {referralLink && (
                      <IconOnlyButton
                        iconRight={Icons.Link}
                        onClick={() => navigator.clipboard.writeText(referralLink)}
                      />
                    )}
                  </Buttons>
                </Frame>
                <ToDesktop>
                  {statusActive && (
                    <BlueFrame
                      onClick={() => {
                        setEditing((v) => !v)
                      }}
                    >
                      {!editing && <Markdown className="__markdown">{customisedMessage}</Markdown>}
                      {editing && (
                        <Input
                          value={customisedMessage}
                          onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                          onChange={(e) => setCustomisedMessage(e.target.value)}
                        />
                      )}
                    </BlueFrame>
                  )}
                  <Buttons>
                    <StyledPrimaryButton
                      disabled={!statusActive}
                      iconRight={Icons.Share}
                      onClick={() => {
                        setEditing(false)
                        setShareModal(true)
                      }}
                    >
                      {t('share')}
                    </StyledPrimaryButton>
                    {referralLink && (
                      <IconOnlyButton
                        iconRight={Icons.Link}
                        onClick={() => navigator.clipboard.writeText(referralLink)}
                      />
                    )}
                  </Buttons>
                </ToDesktop>
              </Grid>
            </WidthBoundary>
          </IntroContent>
          <HowDoesItWorkContent>
            <ExplanationWidthWrapper>
              <DarkMiscTitle>{t('howDoesItWork')}</DarkMiscTitle>
              <RegularGray>{t('howDoesItWorkExplanation')}</RegularGray>
              <BadgeInfos>
                <InfoWithBadge icon={Icons.People}>{t('refer.steps.one')}</InfoWithBadge>
                <InfoWithBadge icon={Icons.Gift}>{t('refer.steps.two')}</InfoWithBadge>
                <InfoWithBadge icon={Icons.Shop}>{t('refer.steps.three')}</InfoWithBadge>
              </BadgeInfos>
              <StyledPrimaryButton
                disabled={!statusActive}
                iconRight={Icons.ArrowRight}
                onClick={() => {
                  setEditing(false)
                  setShareModal(true)
                }}
              >
                {t('referFriend')}
              </StyledPrimaryButton>
            </ExplanationWidthWrapper>
            <DummyCardsWrapper>
              <DummyCards>
                {Array(20)
                  .fill(null)
                  .map((_, i) => i)
                  .map((c) => (
                    <GradientCard key={c} />
                  ))}
              </DummyCards>
            </DummyCardsWrapper>
          </HowDoesItWorkContent>
          {!!referFriendFAQEnabled && (
            <WidthBoundary>
              <FAQRoot>
                <ReferFriendFAQ />
              </FAQRoot>
            </WidthBoundary>
          )}
        </Fragment>
      )}
      {!!showPanelBuilding &&
        !!panelEmail &&
        (panelsProgramFeatureFlag ? (
          <Fragment>
            <MorePanels
              title={t('wannaEarnSomethingYourself')}
              subtitle={t('whyNotJoinOtherPanels')}
              joinablePanels={joinablePanels}
              userData={{ email: panelEmail }}
            />
          </Fragment>
        ) : (
          <BannerWrapper>
            <StyledBanner type="tipIdea" title={t('referral.unavailable.title')}>
              {t('referral.unavailable.description')}
            </StyledBanner>
          </BannerWrapper>
        ))}
      <AboutFooter />
    </Root>
  )
}

const Input = styled.textarea`
  max-height: 200px;
  min-height: 24px;
  padding: 4px;
  resize: both;
  width: 100%;
  ${BodyRegularCSS};
`

const BannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 10;
  min-height: 64px;
  padding: 64px 16px 16px 16px;
  ${mediaQueries.from.breakpoint.desktop} {
    min-height: 96px;
  }
`

const StyledBanner = styled(Banner)`
  width: min(800px, 90vw);
`

const LinkStyle = styled.a`
  ${BodyLargeCSS};
  color: ${colours.brand.blue};
  text-decoration: underline;
  font-weight: 700;
`

const StyledPrimaryButton = styled(PrimaryButton)``

const DummyCardsWrapper = styled.div`
  position: relative;
  width: 99%;
  height: 120px;
  margin-bottom: 56px;
`

const DummyCards = styled.div`
  display: flex;
  gap: 16px;
  overflow: hidden;
  width: 100%;
  position: absolute;
`

const FAQRoot = styled.div`
  margin-bottom: 96px;
  ${mediaQueries.from.breakpoint.desktop} {
    width: 600px;
  }
`

const BadgeInfos = styled.div`
  display: flex;
  justify-content: center;
  gap: 24px;
  align-items: flex-start;
  margin-bottom: 32px;
  ${mediaQueries.to.breakpoint.tablet} {
    flex-direction: column;
    align-items: center;
  }
`

const IconOnlyButton = styled(PrimaryButton)`
  flex-shrink: 8;
`

const Buttons = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;
`

const BlueFrame = styled.div`
  ${BodyLargeCSS};
  align-items: flex-start;
  background-color: ${colours.blues[10]};
  border-radius: 8px;
  box-sizing: border-box;
  color: ${colours.blues[110]};
  cursor: pointer;
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 24px;
  padding: 16px;
`

const DarkMiscTitle = styled.div`
  ${MiscelaneousSpecialTitleBoldCSS};
  color: ${colours.brand.dark};
  margin-bottom: 8px;
`

const Title = styled.div`
  margin-bottom: 16px;
  ${mediaQueries.from.breakpoint.desktop} {
    ${TitleHeaderH1LCSS}
  }
  ${mediaQueries.to.breakpoint.desktop} {
    ${TitleHeaderH1SCSS}
  }
`

const Subtitle = styled(Fonts.IntroSubtextLarge)`
  color: ${colours.mist[800]};
  margin-bottom: 16px;
`

const MainDescription = styled(Fonts.TitleHeaderH2)`
  margin: 0 0 8px 0;
  text-align: left;
`

const RegularGray = styled(Fonts.BodyLarge)`
  color: ${colours.mist[700]};
  margin-bottom: 24px;
`

const ExplanationWidthWrapper = styled(WidthBoundary)`
  padding: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${mediaQueries.from.breakpoint.desktop} {
    width: 832px;
  }
`

const Info = styled.div`
  text-align: left;
`

const Grid = styled.div`
  display: grid;
  gap: 40px;
  margin-bottom: 48px;
  justify-items: center;
  align-items: center;
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    grid-template-columns: repeat(2, 1fr);
    gap: 64px;
    margin-bottom: 96px;
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 10;
  width: 100%;
  padding-top: 64px;
  ${gradients.defaultBackground}
`

const HowDoesItWorkContent = styled.div<{ $showMargin?: boolean }>`
  background-color: ${colours.prisma.white};
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 96px;
  flex-direction: column;
  align-items: center;
`

const IntroContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 0 16px;
  box-sizing: border-box;
`

const Frame = styled.div`
  width: min(440px, 96vw);
  border-radius: 16px;
  box-sizing: border-box;
  padding: 28px 24px 24px;
  gap: 32px;
  background: rgba(255, 255, 255, 0.85);
  border: 2px solid rgba(255, 255, 255, 0.5);
  box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25), 1px 2px 12px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(10px);
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
`

const ToDesktop = styled.div`
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }
`

export default Page
