import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Markdown from 'react-markdown'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

import { AboutFooter } from '@components/atoms/About/About'
import { Accordion } from '@components/atoms/Accordion/Accordion'
import { WidthBoundary } from '@components/atoms/Content/WidthBoundary'
import { Fonts } from '@components/atoms/typography/Fonts'
import { BrandHeader } from '@components/molecules/BrandHeader/BrandHeader'
import { ClaimForm } from '@components/organisms/forms/ClaimForm/ClaimForm'
import { colours } from '@configs/colours'
import { shareKeyPaths } from '@configs/urls'
import { useData } from '@hooks/useData'
import { api } from '@services/api'
import { ReceiveInfo } from '@services/api.types'
import { mediaQueries } from '@utils/mediaQueries'
import { IntroSubtextSmallCSS } from '@components/atoms/typography/css/IntroSubtextSmallCSS'

const Claim: React.FC = () => {
  const { i18n, t } = useTranslation()
  const { shareKey = '' } = useParams()
  const [about, setAbout] = useState(true)
  const [validity, setValidity] = useState(false)
  const [howItWorks, setHowItWorks] = useState(false)
  const navigate = useNavigate()

  const { data } = useData<ReceiveInfo>(
    useCallback(() => api.receiveInfo(shareKey, i18n.language), [i18n.language, shareKey]),
  )
  useEffect(() => {
    if (data?.skip_claim_step) {
      navigate(generatePath(shareKeyPaths.claimSkip, { shareKey }))
    }
  }, [data, navigate, shareKey])
  return (
    <Root>
      <CardOverviewMobile>
        <CardImage src={data?.product?.primary_image ?? undefined} />
        <GreetingBox>{data?.message ? `”${data.message}”` : null}</GreetingBox>
      </CardOverviewMobile>
      <BrandHeader currency={data?.product?.currency} debit_value={data?.balance} />
      {data?.message ? <DesktopGreetingBox>{`”${data.message}”`}</DesktopGreetingBox> : null}
      <StyledWidthBoundary>
        <Title>{data?.product?.name}</Title>
        <Content>
          <InfoSection>
            <MobileOnly>
              <StyledAccordion
                expanded={about}
                onExpandedChange={() => {
                  setAbout((v) => !v)
                }}
                titleText={<Fonts.TitleHeaderH2>{t('about')}</Fonts.TitleHeaderH2>}
              >
                <AccordionText>
                  <Markdown className="__markdown">{data?.product?.description_short}</Markdown>
                </AccordionText>
              </StyledAccordion>
              <StyledAccordion
                expanded={howItWorks}
                onExpandedChange={() => {
                  setHowItWorks((v) => !v)
                }}
                titleText={<Fonts.TitleHeaderH2>{t('howItWorks')}</Fonts.TitleHeaderH2>}
              >
                <AccordionText>
                  <Markdown className="__markdown">{data?.product?.spending_text}</Markdown>
                </AccordionText>
              </StyledAccordion>
              <StyledAccordion
                expanded={validity}
                onExpandedChange={() => {
                  setValidity((v) => !v)
                }}
                titleText={<Fonts.TitleHeaderH2>{t('validity')}</Fonts.TitleHeaderH2>}
              >
                <AccordionText>
                  <Markdown className="__markdown">{data?.product?.expiration_text}</Markdown>
                </AccordionText>
              </StyledAccordion>
            </MobileOnly>
            <DesktopOnly>
              <AboutTitle>{t('about')}</AboutTitle>
              <AboutText>
                <Markdown className="__markdown">{data?.product?.description_short}</Markdown>
              </AboutText>
              <AboutTitle>{t('howItWorks')}</AboutTitle>
              <AboutText>
                <Markdown className="__markdown">{data?.product?.spending_text}</Markdown>
              </AboutText>
              <AboutTitle>{t('validity')}</AboutTitle>
              <AboutText>
                <Markdown className="__markdown">{data?.product?.expiration_text}</Markdown>
              </AboutText>
            </DesktopOnly>
          </InfoSection>
          <FormSection>
            <StyledClaimForm data={data} shareKey={shareKey} />
            <WhatsNextWrapper>
              <Fonts.TitleHeaderH2>{t('redeemSteps.whatsNext')}</Fonts.TitleHeaderH2>
              <WhatsNextPoints>
                <Ol>
                  <Li>{t('redeemSteps.stepOne', { count: 10 })}</Li>
                  <Li>{t('redeemSteps.stepTwo')}</Li>
                  <Li>{t('redeemSteps.stepThree')}</Li>
                </Ol>
              </WhatsNextPoints>
            </WhatsNextWrapper>
          </FormSection>
        </Content>
      </StyledWidthBoundary>
      <AboutFooter />
    </Root>
  )
}

const DesktopGreetingBox = styled.pre`
  padding-top: 64px;
  padding-bottom: 64px;
  padding-right: max(calc((100% / 2) - calc(1200px / 2)), 80px);
  padding-left: max(calc((100% / 2) - calc(1200px / 2)), 80px);
  margin-bottom: 80px;
  text-align: center;
  background-color: ${colours.prisma.background};
  overflow-wrap: break-word;
  white-space: pre-wrap;
  ${IntroSubtextSmallCSS};
  ${mediaQueries.to.breakpoint.mobileL} {
    display: none;
  }
`

const WhatsNextWrapper = styled.div`
  margin-bottom: 40px;
`

const WhatsNextPoints = styled(Fonts.BodyXSmall)`
  color: ${colours.mist[700]};
`

const Ol = styled.ol`
  padding-left: 4px;
`

const Li = styled.li`
  list-style-position: inside;
`

const StyledClaimForm = styled(ClaimForm)`
  margin-bottom: 24px;
`

const InfoSection = styled.section`
  flex: 67% 1 1;
  ${mediaQueries.to.breakpoint.mobileL} {
    margin: 32px 0;
  }
`

const FormSection = styled.section`
  flex: 33% 1 1;
`

const StyledWidthBoundary = styled(WidthBoundary)`
  flex-grow: 10;
  ${mediaQueries.to.breakpoint.mobileL} {
    padding-top: 32px;
  }
`

const Root = styled.article`
  display: flex;
  flex-direction: column;
  flex-grow: 10;
  height: 100%;
  justify-content: space-between;
  width: 100%;
`

const Title = styled(Fonts.TitleHeaderH1S)`
  color: ${colours.brand.dark};
  margin-bottom: 80px;
  font-weight: 600;
  ${mediaQueries.to.breakpoint.desktop} {
    margin-bottom: 24px;
  }
`

const Content = styled.div`
  flex-grow: 10;
  width: 100%;
  ${mediaQueries.from.breakpoint.desktop} {
    display: flex;
    gap: 64px;
  }
`

const CardImage = styled.img`
  width: 100%;
  max-width: 360px;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  outline: 3px solid rgba(255, 255, 255, 0.4);
  outline-offset: -3px;
  background-color: white;
`

const MobileOnly = styled.div`
  ${mediaQueries.from.breakpoint.desktop} {
    display: none;
  }
`

const DesktopOnly = styled.div`
  ${mediaQueries.to.breakpoint.desktop} {
    display: none;
  }
  margin-bottom: 80px;
`

const AboutTitle = styled(Fonts.TitleHeaderH2)`
  color: ${colours.brand.dark};
  margin-bottom: 8px;
`

const AboutText = styled(Fonts.BodyRegular)`
  color: ${colours.mist[900]};
  margin-bottom: 24px;
`

const StyledAccordion = styled(Accordion)`
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 56px;
  }
  color: ${colours.brand.dark};
`

const AccordionText = styled(Fonts.BodyRegular)`
  margin-top: 8px;
  color: ${colours.mist[900]};
`

const CardOverviewMobile = styled.div`
  background-color: ${colours.prisma.background};
  padding: 18px 16px 0 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  ${mediaQueries.from.breakpoint.mobileL} {
    display: none;
  }
`

const GreetingBox = styled.pre`
  color: ${colours.brand.dark};
  margin: 24px 0;
  min-height: 42px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  width: 100%;
  ${IntroSubtextSmallCSS};
`

export default Claim
