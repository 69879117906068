import { Field, Form, Formik, FieldArray } from 'formik'
import { Fragment, Suspense, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { VectorsDict } from '@components/atoms/vectors/dict'
import { PrimaryButton } from '@components/molecules/forms/buttons/PrimaryButton'
import { Radio } from '@components/molecules/forms/inputs/Radio'
import { colours } from '@configs/colours'
import { WithTranslateFormErrors } from '@hoc/WithTranslateErrors'
import { api } from '@services/api'
import { Icons } from '@typeDeclarations/components/atoms/icons'
import { AvailableSurveys, getSurveyConfig } from '@constants/survey'
import { Textarea } from '@components/molecules/forms/inputs/Textarea'
import { TitleHeaderH2SCSS } from '@components/atoms/typography/css/TitleHeaderH2SCSS'
import { SurveyFormValues } from '@services/api.types'

type Props = { object_ref: string; type: AvailableSurveys }

export const Survey: React.FC<Props> = ({ object_ref, type }) => {
  const { t } = useTranslation()
  const [sent, setSent] = useState(false)
  const [error, setError] = useState(false)

  const [initialForm, setInitialForm] = useState<SurveyFormValues>()

  const surveyPk = getSurveyConfig(type).survey_pk

  useEffect(() => {
    api
      .retrieveSurvey(surveyPk)
      .then((s) => {
        const init: SurveyFormValues = {
          content_type: type,
          object_id: object_ref,
          user_agent: 'test-agent',
          answers: s.questions.map((q) => ({
            id: q.id,
            answer_scale: null,
            answer_text: '',
          })),
          questions: s.questions,
        }

        setInitialForm(init)
      })
      .catch(() => setError(true))
  }, [])

  const sendSurvey = async (values: SurveyFormValues) => {
    try {
      delete values.questions
      values.answers = values.answers.map((q) => ({ ...q, question: q.id }))
      await api.shopSurvey(values)
      setSent(true)
      setTimeout(() => setSent(false), 5000)
    } catch (e) {
      console.error('Something went wrong sending the survey: ', e)
      setError(true)
      setTimeout(() => setError(false), 5000)
    }
  }

  if (!initialForm?.questions) return null

  return (
    <Root>
      <Formik
        enableReinitialize
        initialValues={initialForm}
        onSubmit={sendSurvey}
        validationSchema={undefined}
      >
        {({ isSubmitting, touched }) => (
          <WithTranslateFormErrors>
            <Form>
              <Arrangement>
                {!!initialForm.questions && (
                  <FieldArray
                    name="answers"
                    render={() => {
                      return initialForm.questions
                        ?.filter(({ visible }) => !!visible)
                        .map((q, i) => (
                          <Fragment key={q.id}>
                            <Question>{t(q.question_text_translation_key)}</Question>
                            <RadiosArrangement>
                              <StyledRadio
                                customToggler={(checked) => (
                                  <CenterHorizontally>
                                    <Suspense>
                                      <VectorsDict.smileSad
                                        fill={checked ? colours.error.red : colours.prisma.white}
                                        stroke={checked ? colours.tomato[20] : colours.mist[200]}
                                      />
                                    </Suspense>
                                  </CenterHorizontally>
                                )}
                                disabled={sent || error}
                                name={`answers[${i}].answer_scale`}
                                value="1"
                              />
                              <StyledRadio
                                customToggler={(checked) => (
                                  <CenterHorizontally>
                                    <Suspense>
                                      <VectorsDict.smileUnhappy
                                        fill={
                                          checked ? colours.mandarin[100] : colours.prisma.white
                                        }
                                        stroke={checked ? colours.mandarin[20] : colours.mist[200]}
                                      />
                                    </Suspense>
                                  </CenterHorizontally>
                                )}
                                disabled={sent || error}
                                name={`answers[${i}].answer_scale`}
                                value="2"
                              />
                              <StyledRadio
                                customToggler={(checked) => (
                                  <CenterHorizontally>
                                    <Suspense>
                                      <VectorsDict.smileIndifferent
                                        fill={
                                          checked ? colours.sunflower[100] : colours.prisma.white
                                        }
                                        stroke={checked ? colours.sunflower[20] : colours.mist[200]}
                                      />
                                    </Suspense>
                                  </CenterHorizontally>
                                )}
                                disabled={sent || error}
                                name={`answers[${i}].answer_scale`}
                                value="3"
                              />
                              <StyledRadio
                                customToggler={(checked) => (
                                  <CenterHorizontally>
                                    <Suspense>
                                      <VectorsDict.smileSmile
                                        fill={checked ? colours.plant[100] : colours.prisma.white}
                                        stroke={checked ? colours.plant[20] : colours.mist[200]}
                                      />
                                    </Suspense>
                                  </CenterHorizontally>
                                )}
                                disabled={sent || error}
                                name={`answers[${i}].answer_scale`}
                                value="4"
                              />
                              <StyledRadio
                                customToggler={(checked) => (
                                  <CenterHorizontally>
                                    <Suspense>
                                      <VectorsDict.smileHappy
                                        fill={checked ? colours.brand.blue : colours.prisma.white}
                                        stroke={checked ? colours.blues[20] : colours.mist[200]}
                                      />
                                    </Suspense>
                                  </CenterHorizontally>
                                )}
                                disabled={sent || error}
                                name={`answers[${i}].answer_scale`}
                                value="5"
                              />
                            </RadiosArrangement>
                            <Field
                              name={`answers[${i}].answer_text`}
                              as={StyledTextarea}
                              description={t('survey.elaborate')}
                            />
                          </Fragment>
                        ))
                    }}
                  />
                )}
                <StyledPrimaryButton
                  disabled={isSubmitting || sent || error || Object.keys(touched).length === 0}
                  iconRight={!sent ? Icons.PaperPlane : undefined}
                  style={{ width: 'fit-content' }}
                  type="submit"
                >
                  {error
                    ? t('somethingWentWrong')
                    : sent
                    ? t('thankYou')
                    : t('forms.actions.sendFeedback')}
                </StyledPrimaryButton>
              </Arrangement>
            </Form>
          </WithTranslateFormErrors>
        )}
      </Formik>
    </Root>
  )
}

const StyledPrimaryButton = styled(PrimaryButton)<{ disabled?: boolean }>`
  cursor: ${(p) => (p.disabled ? 'unset' : 'pointer')};
`

const StyledRadio = styled(Radio)<{ disabled?: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`

const CenterHorizontally = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const Arrangement = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Root = styled.section`
  min-width: 304px;
  max-width: 420px;
  box-sizing: border-box;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
`

const RadiosArrangement = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  margin-bottom: 16px;
`

const StyledTextarea = styled(Textarea)`
  margin-bottom: 24px;
  text-align: center;
`

const Question = styled.div`
  ${TitleHeaderH2SCSS};
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
`
